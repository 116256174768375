@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_responsive";
@import "_color";
@import "_component";
@import "_home";
@import "_products";
@import "_product";
@import "_tab";
@import "_blog";
@import "_blogDetail";
@import "_carts";
@import "_map";
@import "_filter";
@import "_policy";

@import "_button";
@import "_footer";
@import "_attachedItem";
@import "_support";
@import "_syncfunction";
@import "_contact";
@import "_responsive";


@font-face {
  font-family: 'Open Sans', sans-serif;
  src: url("../public/font/OpenSans-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("../public/font/Inter-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

:root {
  --hegiht-header: 64px;
}

.blog_detail {

  span {
    display: initial !important;
  }

  a {
    display: initial;
    color: $primary;
  }

  ul {
    list-style: inside;
    list-style-position: inside;
    list-style-image: initial;
    list-style-type: decimal;
    margin: 0;
    padding: 0;

    li {
      //list-style: decimal !important;
    }
  }
}

#form_in_modal_group_content_rte-edit-view {


  a {
    display: initial;
  }

  ul {
    list-style: inside;
    list-style-position: inside;
    list-style-image: initial;
    list-style-type: decimal;
    margin: 0;
    padding: 0;

    li {
      //list-style: decimal !important;
    }
  }

  span {
    display: contents;
    white-space: normal !important
  }
}

// .e-content {
//   padding: 2.5rem !important;
// }

// .e-rte-content {
//   border-radius: 16px;
//   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.08) !important;
//   border: none !important;
// }

.wrap-product-detail {
  max-width: 1280px;
  margin: auto;
  margin-bottom: 1.5rem;

  .e-richtexteditor .e-rte-content .e-content {
    padding: 0;
    min-height: 20px;
    @include MediaQuery($desktop) {
      padding: 0 1.5rem;
    }
  }
}

.mt-product-detail {
  margin-bottom: 0;
  margin-top: 1.5rem !important;
}

.e-richtexteditor .e-rte-content {
  border: none;
}


textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

span {
  // display: inline-block;
  // white-space: nowrap;
  // overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: inherit;
}

a {
  // display: inline-block;
  // white-space: nowrap;
  // overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: inherit;
}

html {
  font-family: 'Open Sans', sans-serif !important;
  scroll-padding-top: 65px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #e8e8e8;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.custom-badge .ant-badge-count {
  transform: none !important;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

.hide_scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

// @media(prefers-reduced-motion) {
//   .animate_hidden {
//     transition: none !important;
//     transform: none !important
//   }
// }

// .animate_hidden {
//   opacity: 0;
//   filter: blur(5px);
//   transform: translateY(50%);
//   transition: all 0.8s;
// }

// .animate_show {
//   opacity: 1;
//   filter: blur(0);
//   transition-delay: 100ms;
//   transform: translateY(0);
// }

.filter_layout {
  .ant-select-selector {
    min-height: 44px !important;
    border-radius: 4px;

  }
}

.ant-select-arrow {
  color: #595959 !important;
}

.product_filter_group {
  .ant-picker {
    height: 44px !important;
    border-radius: 4px;
  }
}

.break_2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-height: 5em;
  // white-space: normal !important;
}

.break {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// img {
//   display: block !important;
//   max-width: 100% !important;
//   max-height: 100% !important;
//   width: auto !important;
//   height: auto;
// }



@media only screen and (max-width: 500px) {
  .ant-picker-dropdown {
    left: 30px !important;
  }
}

.shadow-special-product {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

p>span {
  white-space: normal;
}

p>a {
  white-space: normal;
}

p {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  // max-height: 5em;
}

#description_product span {
  white-space: normal;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .ant-picker-input {

    select,
    textarea,
    input {
      font-size: 14px !important;
    }
  }
}

#description_product>img {
  width: fit-content !important;
}

.slick-list {
  // min-height: 380px;
  padding-bottom: 12px !important;
}

#maintain {
  background-image: url(../public/asset/image/phone.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 533px 610px;
  background-position: right;
  font-feature-settings: 'liga' off;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px !important;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px !important;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px !important;
  }
}

.iframe-container-div::after {
  content: "";
  background: transparent;
  width: 100%;
  height: 100%;
  min-height: 1px;
  position: absolute;
  top: 0;
}

.e-control,
.e-css {
  font-size: 14px !important;
}


.e-rte-content {
  img {
    width: revert-layer !important;
    display: inline-block;
  }

  a {
    color: #0e7490;
    display: initial;
  }

  h3 {
    font-size: 1.31em;
    font-weight: 400;
    margin: 10px 0;
  }

  h1 {
    font-size: 2.17em;
    font-weight: 400;
    line-height: 1;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.74em;
    font-weight: 400;
    margin: 10px 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
  }

  p {
    margin: 0 0 10px;
    margin-bottom: 10px;
  }
}

#form_in_modal_group_content_rte-edit-view_descr {
  span {
    display: initial;
  }

  a {
    color: #0e7490;
    display: initial;
  }

  h3 {
    font-size: 1.31em;
    font-weight: 400;
    margin: 10px 0;
  }

  h1 {
    font-size: 2.17em;
    font-weight: 400;
    line-height: 1;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.74em;
    font-weight: 400;
    margin: 10px 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
  }

  p {
    margin: 0 0 10px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  img {
    width: revert-layer !important;
  }
}

.wrap-toolkit {
  width: 62px;
  height: auto;
  right: 0;
  justify-content: flex-start;
  border-radius: 20px 0 0px 20px;
  align-items: center;
  transform: none;
  flex-direction: column;
  position: fixed;
  display: flex;
  cursor: pointer;
  background: rgba(31, 31, 31, 1);
  top: 48%;
  z-index: 21;

  .content {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 0.8rem;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    padding: 12px 8px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 6px;

      img.ico-support{
          width: 20px !important;
      }

      .support_popup{
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 21;
        top: 0%;
        gap: 8px;
        right: 100%;
        // transition-duration: 0.8s;
        // transition-property: transform, opacity;
        // transform: translate(100%, 0);
        // opacity: 0;
        display: none;
        opacity: 0;
        animation: FadeOut 0.2s ease-in-out;

        .content {
          background: #006B6D;
          padding: 10px 16px;
          gap: 8px;
          align-items: center ;
          flex-direction: row ;
          display: flex;
          border-radius: 8px;
          width: 158px;
          border: none;
          margin-bottom: 8px;

          img{
            width: 16px !important;;
          }
        }
        .content:hover{
          background: rgb(1,106,109, 0.9);
        }
      }

      .sp_phone{
        top: -105%;
      }

      
  }
  .content:hover .support_popup{
    display: block;
    opacity: 1;
    animation: FadeIn 0.5s ease-in-out;
  }
}

@keyframes slideaway {
  from { display: block; }
  to { opacity: 0;}
}

@keyframes fade-out {
  100% {
      opacity: 0;
      display: none;
  }
}

@keyframes FadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeOut {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display:none;
    opacity: 0;
  }
}

.wrap-toolkit:has(> .filter_button), .wrap-toolkit:has(> .wrap_combo){
  top: 40%;
}


@media (max-width: 1400px) {
  .wrap-toolkit{
    top: 32%;
  }

  .wrap-toolkit:has(> .filter_button), .wrap-toolkit:has(> .wrap_combo){
    top: 30%;
  }

  .wrap-toolkit {
      width: 42px;
      border-radius: 12px 0 0px 12px;
      background: rgba(0, 0, 0, 0.7);

      .content {
        // .support_popup {
        //   right: 42px;
        // }
  
        p{
            display: none !important;
        }

        img{
            width: 28px !important;
        }
        img.ico-support{
            width: 22px !important;
        }
      }
      
  }
}

@media (max-width: 820px) {
  .wrap-toolkit{
    top: 38%;
  }

  .wrap-toolkit:has(> .filter_button), .wrap-toolkit:has(> .wrap_combo){
    top: 35%;
  }
}

.wrap-loading-data {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
  background-size: 400% 100%;
  animation-name: css-dev-only-do-not-override-1uq9j6g-ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  width: 100% !important;
  height: 100%;

  .loading-data{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
    background-size: 400% 100%;
    animation-name: css-dev-only-do-not-override-1uq9j6g-ant-skeleton-loading;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    img {
      width: 48px;
      height: 48px;
      line-height: 48px;
      max-width: 192px;
      max-height: 192px;
    }
  }
}

#search_content{
  // padding: 2.5rem !important;
  border-radius: 16px;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08), 0 0 2px 0 rgba(0, 0, 0, .08) !important;
  border: none !important;
}

.wrap-search-keyword {
  a {
    color: $primary;
  }
}

.whiteSpaceNormal{
  white-space: normal !important;
}

.notes-branch {
  color: #e03616;
  font-size: 0.9rem;
}