@font-face {
    font-family: 'Open Sans', sans-serif;
    src: url("../public/font/OpenSans-Regular.ttf");
    font-display: swap;
}

.slick-next {
    width: 3rem !important;
    height: 3rem !important;
    right: 1rem !important;
    z-index: 22;
}

.slick-prev {
    width: 3rem !important;
    height: 3rem !important;
    left: 1rem !important;
    z-index: 2;
}

.slick-prev::before {
    font-size: 3rem !important;
    color: #858c94 !important;
}

.slick-next::before {
    font-size: 3rem !important;
    color: #858c94 !important;
}

.slick-dots {
    bottom: 1rem !important;
}

.slick-dots li button:before {
    color: #D9D9D9 !important;
    font-size: 0.75rem !important;
}

.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #FFFFFF !important;
}

.height-banner {
    height: 686px;
}



.wrap-title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .divider {
        flex: 1;
        border: 1px solid #F0F0F0
    }


    .title {
        padding: 2.5rem 1rem;
        color: #434343;
        font-size: 2rem;
        font-weight: 700;
    }

    .title--box {
        padding: 1rem !important;
        color: #434343;
        font-size: 2rem;
        font-weight: 700;
        background: #FAFAFA;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
    }
}

.wrap-product-groups_mobile {
    overflow-y: auto;
    gap: 12px !important;
    padding: 16px !important;
    grid-template-columns: repeat(2, 1fr) !important;
}

.wrap-product-groups_desktop {
    display: grid;
    grid-gap: 24px;
    padding: 0 5%;
    margin-bottom: 4rem;
    grid-template-columns: repeat(5, 1fr);

    .wrap-product-card {
        display: flex !important;

        .product-card {
            background: #FFFFFF;
            border-radius: 8px;
            //margin: 0px 1rem;

            .name-product {
                text-transform: uppercase;
                color: #595959;
                text-align: center;
            }
        }

        .background-product {
            overflow: hidden;
            background-color: rgb(89 89 89 / 4%);
            border-radius: 99%;
            -webkit-border-radius: 99%;
            -moz-border-radius: 99%;
            -ms-border-radius: 99%;
            -o-border-radius: 99%;
            width: 13rem;
            height: 13rem;
        }

        @include MediaQuery($md) {
            .background-product {
                width: 8rem;
                height: 8rem;
            }
        }
    }
}

.homepage-component {
    background: #FAFAFA;
    
    .product-group {
        background: #FAFAFA;
        position: relative;

        .feature_product_title {
            position: absolute;
            top: -87px;
        }

        .wrap-product-groups {
            display: grid;
            grid-gap: 24px;
            padding: 0 5%;
            margin-bottom: 4rem;
            grid-template-columns: repeat(5, 1fr);

            .wrap-product-card {
                display: flex !important;

                .product-card {
                    background: #FFFFFF;
                    border-radius: 8px;
                    //margin: 0px 1rem;

                    .name-product {
                        text-transform: uppercase;
                        color: #595959;
                        text-align: center;
                    }
                }

                .background-product {
                    overflow: hidden;
                    background-color: rgb(89 89 89 / 4%);
                    border-radius: 99%;
                    -webkit-border-radius: 99%;
                    -moz-border-radius: 99%;
                    -ms-border-radius: 99%;
                    -o-border-radius: 99%;
                    width: 13rem;
                    height: 13rem;
                }
            }
        }

    }

    @include MediaQuery($md) {
        .background-product {
            width: 8rem !important;
            height: 8rem !important;
        }
        .banner {
            position: relative;
            overflow: hidden;

            img {
                height: auto !important;
            }
        }

        .parent-group {
            .wrap-title {
                display: none;
            }

            ::-webkit-scrollbar {
                height: 4px;
            }

            ::-webkit-scrollbar-button {
                width: 60px;
                background: #FAFAFA
            }

            ::-webkit-scrollbar-thumb {
                background: #2EC5CE;
                border-radius: 2px;
            }

            .wrap-product-groups {
                overflow-y: auto;
                gap: 12px !important;
                padding: 16px !important;
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }
    }

    .product-2-bg {
        background: #ffffff;
    }

    .product-1 {
        overflow-x: hidden;

        @include MediaQuery($sm) {
            display: flex;
            flex-direction: column-reverse;



            .product-1--image {
                width: 100% !important;
            }

            .product-1--image>.wrap-image {
                height: 400px;
            }
        }

        .left {
            .wrap-image {
                border-radius: 16px;
                overflow: hidden;
                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;
                -ms-border-radius: 16px;
                -o-border-radius: 16px;
            }
        }

        .right {
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;

            .title {
                color: #434343;
                font-weight: 700;
                font-size: 2rem;
            }

            .content {
                font-size: 0.938rem;
                color: #434343;
                width: 63%;
            }

            .wrap-image {
                border-radius: 16px;
                overflow: hidden;
                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;
                -ms-border-radius: 16px;
                -o-border-radius: 16px;
            }


        }

    }

    .button-album-background {
        background-color: #ffffff;
    }

    .button-album {
        background: #262626;
        border-radius: 8px;
        padding: 0.75rem 2.5rem;
        font-size: 1rem;
        color: #FFFFFF;
        margin-top: 3rem;
    }

    .button-navigation {
        cursor: pointer;
        background: rgb(255 255 255 / 40%);
        border-radius: 99%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
    }

    .button-navigation:hover {
        background: rgb(255 255 255 / 60%) !important;
    }

    .wrap-comment-customer {
        width: 100% !important;
        height: 708px !important;
        position: relative;


        .background-content {
            width: 100%;
            height: 708px;
            background: rgb(140 140 140 / 40%);
            position: absolute;
            top: 0px;
            left: 0px;

            .wrap-comment {
                width: 600px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                margin-left: 24px;
                margin-right: 24px;

                @include MediaQuery($sm) {
                    width: 100%;
                    margin-left: 0;
                    padding-right: 12px;
                    padding-left: 12px;

                    .title-comment {
                        font-size: 1.5rem !important;
                    }
                }

                .title-comment {
                    font-size: 2rem;
                    font-family: 'Open Sans', sans-serif;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-weight: 700;
                }

                .box-comment {
                    width: 100%;
                    height: 340px;
                    border-radius: 16px;
                    background-color: #fff;
                    -webkit-border-radius: 16px;
                    -moz-border-radius: 16px;
                    -ms-border-radius: 16px;
                    -o-border-radius: 16px;

                    .wrap-image-customer {
                        border-radius: 4px;
                        overflow: hidden;
                    }

                    .name-customer {
                        text-transform: uppercase;
                        font-size: 1rem;
                        color: #434343;
                        font-weight: 700;
                    }

                    .address {
                        font-size: 1rem;
                        color: #8C8C8C;
                    }

                    .content-comment {
                        color: #434343;
                        font-size: 0.875rem;
                    }
                }

                .navigation-comment {
                    display: flex;
                    gap: 1.5rem;

                }
            }
        }
    }
}

@include MediaQuery($sm) {
    .product-2 {
        flex-direction: column !important;
    }
}


.footer-component {
    // font-family: 'Open Sans', sans-serif;
    background-color: #fff;

    .footer_content_layout {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto;
        justify-content: space-between;
    }

    .map {
        @apply my-auto ml-12
    }

    @include MediaQuery($md) {
        .footer_content_layout {
            display: flex;
            flex-direction: column;
            padding: 16px;
            align-items: center;

            .wrap-content {
                margin-right: 0 !important;
                justify-content: center;

                span {
                    text-align: center;
                }
            }
        }

        .map {
            margin-left: 0 !important;
        }

        .footer-social {
            margin: auto 16px !important;
            width: 70vw !important;
        }

        .footer-upper {
            height: auto !important;
            width: 100% !important;
        }
    }
    
    .footer-upper {
        background-image: url("../public/asset/image/Earth.webp");
        background-repeat: no-repeat;
        background-position: right;
        max-width: 1280px;
        height: 350px;
        margin: auto;
    }

    .wrap-content {
        flex-wrap: wrap;
        max-width: 300px;

        .fist-title {
            font-weight: bold;
            color: #595959;
            font-size: 1.5rem;
        }

        .second-title {
            color: #686868;
            font-size: 1rem;
            font-weight: 500;
        }

        .second-title:hover {
            color: #12C2C2;
            cursor: pointer;
        }

        p {
            color: #686868;
            font-size: 0.875rem;

        }
    }



    .footer-social--icons {
        position: absolute;
        top: -15px;
        left: 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;

        .footer-social--icons--item {
            width: 44px;
            height: 44px;
            background: #FAFAFA;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.12);
            border-radius: 9999999px;
            padding: 10px;
            z-index: 4;
        }

        .footer-social--icons--item--active {
            background: #ffffff !important;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
        }
    }

    .wrap_footer-social {
        background: #FFFFFF;
        height: 206px;
        width: 30rem;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        display: -webkit-inline-box;
        border-radius: 8px;
        padding: 8px 16px;
    }

    .footer-social {
        z-index: 3;
        margin: auto;
        position: relative;
        background: #FFFFFF;
        overflow-x: auto;
        display: -webkit-inline-box;
        gap: 1rem;

        .heading {
            color: #434343;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .footer-social::-webkit-scrollbar {
        height: 6px;

    }

    .footer-social::-webkit-scrollbar-thumb {
        background: #F0F0F0;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
    }

    .footer-social::-webkit-scrollbar-track {
        background: #FaFaFa;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    .lastTitle {
        color: #686868;
        font-size: 1rem;
        font-weight: 500;
    }
}

.wrap-item-silder {
    width: 100%;
    height: 38rem;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-left: 4rem;
    padding-right: 4rem;
}

.slick-center {
    .featured_item {
        height: 36rem !important;
    }

    .head-1 {
        font-weight: bold !important;
        color: #434343 !important;
    }

    .price-1 {
        font-size: 1.14rem !important;
        font-weight: 500 !important;
    }

}

@media only screen and (max-width: 600px) {
    .wrap-item-silder {
        height: 27rem;
    }

    .slick-center {
        .featured_item {
            height: 400px !important;
        }
    }

    .featured_item {
        height: 242px !important;
    }
}

.featured_item {
    border-radius: 8px;
    height: 30rem;

    .image {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        height: 100% !important;
        width: 100% !important;
    }

    .head-1 {
        color: #434343;
        font-weight: 500;
        font-size: 1rem;
    }

    .price-1 {
        color: #F5222D;
        font-weight: 500;
        font-size: 2rem;
    }

    .wrap-image-featured-item {
        height: 80%;
        width: 100%;
        display: flex;
    }

    .wrap-content-featured-item {
        height: 20%;
    }
}

.featured_item--active {
    height: 30rem;

    .head-1 {
        font-size: 1.25rem;
        font-weight: bold;
        color: #434343;
    }

    .price-1 {
        font-size: 2.25rem;
        font-weight: 500;
    }
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

@include MediaQuery($sm) {
    .header {
        padding: 0 4px !important;
    }
}

.padding_x {
    padding-left: 90px;
    padding-right: 90px;
}

@include MediaQuery($desktop) {
    .padding_x {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .wrap_footer-social {
        width: auto !important;
    }
}


.header {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: 64px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
        0px 2px 4px rgba(0, 0, 0, 0.08);
}

.body {
    margin-top: 64px;
}



.header-component {
    position: relative;
    height: var(--hegiht-header);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;

    @include MediaQuery($lg) {
        .toggle-ham {
            display: block !important;
        }

        .header-component-content--menu {
            display: none
        }

        .input--search {
            display: none !important;
        }

        .logo {
            width: 140px;
            height: 58px;
            margin-right: auto !important;
            margin: auto;
        }

        .header-component-content--search--icon-search {
            display: block !important;
        }

        .header-component-content--search--icon {
            // width: 12px;
            // height: 12px;
        }

        .header-component-content--logo {
            margin: auto
        }
    }

    .header-component-content--search--icon-search {
        display: none;
    }

    /* Hamburger icon */
    .toggle-ham {
        display: none;

        .hamb {
            display: flex;
            flex-direction: column;
            gap: 4px;
            cursor: pointer;
            float: right;
            padding: 40px 20px;
        }

        .hamb-line {
            background: #434343;
            display: block;
            height: 2px;
            position: relative;
            width: 24px;

        }

        /* Style span tag */

        // .hamb-line::before,
        // .hamb-line::after {
        //     background: #434343;
        //     content: '';
        //     display: block;
        //     height: 100%;
        //     position: absolute;
        //     transition: all .2s ease-out;
        //     width: 100%;
        // }

        // .hamb-line::before {
        //     top: 5px;
        // }

        // .hamb-line::after {
        //     top: -5px;
        // }

    }

    .logo {
        margin-right: 130px
    }

    .wrap-input {
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        background: #FFFFFF;
        width: 200px;
        height: 30px;
        display: flex;
        flex-direction: row;

        .input {
            flex: 1;
            display: flex;
            padding: 4px 12px;

            input {
                font-weight: 400;
                font-color: rgba(0, 0, 0, 0.25);
                font-size: 0.8rem;
                width: 140px;
                box-sizing: border-box;
                border: 0 solid #e5e7eb;
            }

            input:focus {
                outline: none;
            }
        }

        .icon {
            border-left: 1px solid #D9D9D9;
            display: flex;
            margin: auto;
            padding: 7.75px
        }
    }
}

.menu--mobile {
    z-index: 50;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    width: 80%;
    background: #F5F5F5;
    left: 0px;
    top: 0px;

    .menu--mobile--logo-zone {
        position: relative;
        width: 100%;
        height: 120px;
        display: flex;
        background: #FFFFFF;
        margin-bottom: 16px;

        .menu--mobile--logo-zone--logo {
            margin: auto;
            width: 180px;
            height: 58px;
        }

        .menu--mobile--logo-zone--close {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }

    }

    .menu--mobile--menu-zone {
        display: flex;
        flex-direction: column;
        // gap: 8px;

        .menu--mobile--menu-zone--parent-zone {
            display: flex;
            flex-direction: column;
            background-color: #FFFFFF;

        }

        .menu--mobile--menu-zone--parent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 12px 16px;

            .menu--mobile--menu-zone--parent--icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            .menu--mobile--menu-zone--parent--content {
                font-weight: 500;
                color: #434343;
                font-size: 1rem;
            }
        }

        .menu--mobile--menu-zone--child {
            padding: 12px 0;

            .menu--mobile--menu-zone--child--content {
                font-size: 1rem;
                color: #434343;
                font-weight: 400;
            }
        }

        .menu--mobile--menu-zone--child-zone {
            display: flex;
            flex-direction: column;
        }
    }
}

.menu {
    display: flex;
    flex-direction: row;

    .menuBarItem {
        margin-right: 16px;

        .item {
            height: 44px;
            display: flex;
            gap: 10px;
            flex-direction: row;
            align-items: center;
            margin: auto;
            padding: 12px;
            color: #8C8C8C;
            font-weight: 400;

            .text {
                font-weight: 400;
                color: #8C8C8C;
                font-size: 1rem;
                flex: 1
            }
        }

        .item-active {
            font-weight: 700 !important;
            color: #08979C !important;
        }

        .divider {
            visibility: hidden;
            background: #13C2C2;
            width: 100%;
            height: 2px;
        }

        .divider-active {
            visibility: visible;
        }
    }

    .subMenu {
        min-height: 580px;
        z-index: 20;
        visibility: hidden;
        position: absolute;
        width: 100%;
        //height: 300px;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 44px 50px;
        left: 0;
        top: 57px;
        overflow-y: auto;
        max-height: 100vh;
        white-space: nowrap;
        
        .subMenu_layout {
            margin-right: 8.25rem;
            width: 297px;
        }

        .items {
            top: 0;
            visibility: hidden;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .subMenu_content_parent {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1.5rem;
            color: #8C8C8C;
        }

        .subMenu_content_child {
            font-size: 1rem;
            font-weight: 400;
            color: #8C8C8C;
        }

        .subMenu_content_child:hover {
            color: #595959 !important;
            font-weight: 500;
        }

        .active {
            font-weight: 500 !important;
            color: #595959 !important;
        }

        .image {
            overflow: hidden;
            border-radius: 16px !important;
            border: 1px solid #ffffff;
            width: 457px;
        }

        .image>img {
            border-radius: 8px !important;
            aspect-ratio: 457/410;
        }
    }

    .parent:hover {
        .subMenu {
            visibility: visible !important;
            transform-origin: top center;
            animation: growDown 500ms ease-in-out forwards;
        }

    }

    .subMenu:hover {
        visibility: visible !important;
    }

    @keyframes growDown {
        0% {
            transform: scaleY(0)
        }

        80% {
            transform: scaleY(1.1)
        }

        100% {
            transform: scaleY(1)
        }
    }

}

.messenger {
    cursor: pointer;
    position: fixed;
    right: 24px;
    top: 90%;
}

.gotop {
    cursor: pointer;
    position: fixed;
    right: 36px;
    top: calc(90% - 80px);
    background: #686868;
    border-radius: 50%;
}

@include MediaQuery($sm) {
    .messenger {
        display: none;
    }

    .gotop {
        display: none;
    }
}

// .banner{
//     height: 686px;
// }

.overflow-unset {
    overflow: unset !important;
}
.body_padding {
    max-width: 1280px;
    margin: auto;
}

@media only screen and (max-width: 1280px) and (min-width: 900px) {
    .footer-component .footer-upper, .body_padding {
        padding: 0 1.5rem;
    }
}