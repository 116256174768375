#header_attached {
    z-index: 10;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
}

#body_attached {
    background-color: #fff;
}

.shadow_attached_item {
    border-radius: 8px;
    background: #FFF;

    /* Web/card-shadow */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
}