.footer_address {
    background: #FFFFFF;
    /* Web/card-shadow */

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.ft_tab_active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px 0px 0px;
    padding: 9px 16px;
    color: $primary;
}

.ft_tab {
    display: flex;
    justify-content: center;
    align-content: center;
    background: #FFFFFF;
    /* Web/card-shadow */
    padding: 9px 16px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
}