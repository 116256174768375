.blog_layout {
    font-family: 'Open Sans', sans-serif;
    background: #FAFAFA;

    span {
        white-space: normal;
    }

    .blog_filter {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        width: 320px;
        padding: 24px;

        .sub_menu_heading {
            font-weight: 500;
            font-size: 1.15rem;
            color: #434343;
            text-transform: uppercase;
        }

        .sub_menu_line {
            margin-top: 8px;
            height: 1px;
            background-color: #D9D9D9;
        }

        .newest_news_head {
            white-space: normal !important;
            font-weight: bold;
            font-size: 0.9rem;
            color: #434343;
        }

        .newest_news_content {
            color: #434343;
            font-weight: 400;
            font-size: 0.8rem;
        }

        .newest_new_image {
            width: 100% !important;
            height: 100% !important;
        }

        .wrapImageActicle {
            width: 90px;
            height: 90px;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #e8e8e8;
        }

        .wrapContentActicle {
            width: calc(100% - 80px);
        }

        .wrapContent--active {
            display: block;
            transform-origin: top center;
            animation: growDown 500ms ease-in-out forwards;
        }

        .wrapContent {
            display: none;
            transform-origin: top center;
            animation: growTop 500ms ease-in-out forwards;
        }

        @keyframes growTop {
            0% {
                transform: scaleY(1)
            }

            100% {
                transform: scaleY(0);
            }
        }

        @keyframes growDown {
            0% {
                transform: scaleY(0)
            }

            80% {
                transform: scaleY(1.1)
            }

            100% {
                transform: scaleY(1)
            }
        }
    }

    .blog_detail {
        flex: 1;

        .blog_detail--menu {
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            background: #FFFFFF;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 24px;
            margin-bottom: 3.2rem;

            h3 {
                font-weight: 500;
                font-size: 1.25rem;
                color: #434343;
                text-transform: uppercase;
            }

            h4 {
                font-weight: bold;
            }

            p {
                font-weight: normal;
            }

            .menu--icon--active {
                rotate: 90deg;
            }
        }


    }
}

.wrapItemProductBlogDetail {
    // height: 28rem !important;
}

.plus {
    --b: 2px;
    /* the thickness */
    width: 24px;
    /* the size */
    aspect-ratio: 1;
    border: 10px solid #434343;
    /* the outer space */
    background:
        conic-gradient(from 90deg at var(--b) var(--b), #434343 90deg, #fff 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/ calc(50% + var(--b)) calc(50% + var(--b));
    display: inline-block;
}

.alt {
    border: none;
    background:
        conic-gradient(from 90deg at var(--b) var(--b), #fff 90deg, #434343 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/ calc(50% + var(--b)) calc(50% + var(--b));
}