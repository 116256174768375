@layer components {
    .base_button {
      
    }
    .base_input {
        
        input{
            width: 100%;
            border: 1px solid #D9D9D9;
            padding: 0.75rem 0.75rem;
            font-size: 1rem;
        }
        input:focus-visible{
            outline: none;
        }
        border-radius: 2px;
        background: #FFFFFF;
        box-sizing: border-box;
        display: flex;
        button {
            background: #434343;
            border: 1px solid #434343;
            padding: 0.75rem 1.625rem;
            color: #FAFAFA;
            font-size: 1rem;
        }
    }
}