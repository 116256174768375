.products_head {
    z-index: 4;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    height: 90px;
    background-image: url(../public/asset/image/products_head.webp);
    background-repeat: no-repeat;
    background-size: cover;

    @include MediaQuery($sm) {
        height: 50px;

        .wrapperProducts {
            width: 100% !important;

            .itemActive {
                font-size: 1rem !important;
            }

            .wrapperScroller {
                top: 5px !important;
                left: 0 !important;
            }
        }
    }

    .opacity_bg {
        position: absolute;
        width: 100%;
        height: 90px;
        background: #000000;
        opacity: 0.6;
        display: flex;
        justify-content: center;
        align-items: center;

        @include MediaQuery($sm) {
            height: 50px;
        }
    }
}

.absolute_center {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    /* Need a specific value to work */
}

.policy--layout {
    // margin-top: 154px;
    display: flex;
    background: #F5F5F5;

    padding: 44px;
    justify-content: center;
    align-items: center;
    min-height: 80vh;

    .policy--paper {
        min-height: 75vh;
        width: 1000px;
        padding: 32px 74px;
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);


        h3 {
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
        }

        p {
            font-family: 'Open Sans', sans-serif;
            line-height: 1.6rem;
        }
    }

    @include MediaQuery($sm) {
        padding: 16px;
        margin-top: 0 !important;

        .policy--paper {
            padding: 16px;

        }
    }
}

.horizontal-scroll-wrapper {
    position: relative;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    margin-left: 24px;
    margin-right: 24px;
    height: 90px;
    width: 85%;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;

    div {
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 1rem;
    }

    @include MediaQuery($sm) {
        padding-left: 20px;

        .itemActive {
            font-size: 1rem !important;
        }
    }

    .itemActive {
        //margin: 0 15px;
        //margin: auto;
        font-weight: 500;
        font-size: 1.5rem;
        color: #FFFFFF;
        text-transform: uppercase;

    }

    .itemInActive {
        // margin: 0 15px;
        color: #8C8C8C;
        font-weight: 400;
        font-size: 1rem;
        display: flex;
    }

    .subMenu {
        align-items: flex-start;
        min-height: 580px;
        z-index: 20;
        visibility: hidden;
        position: fixed;
        width: 100%;
        //height: 300px;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        padding: 44px 50px;
        left: 0;
        top: 147px;
        overflow-y: auto;
        max-height: 100vh;

        .subMenu_layout {
            margin-right: 8.25rem;
            width: 297px;
        }

        .items {
            top: 0;
            visibility: hidden;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .subMenu_content_parent {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1.5rem;
            color: #8C8C8C;
        }

        .subMenu_content_child {
            font-size: 1rem;
            font-weight: 400;
            color: #8C8C8C;
        }

        .subMenu_content_child:hover {
            color: #595959 !important;
            font-weight: 500;
        }

        .active {
            font-weight: 500 !important;
            color: #595959 !important;
        }

        .image {
            overflow: hidden;
            border-radius: 16px !important;
            border: 1px solid #ffffff;
            width: 457px;
        }

        .image>img {
            border-radius: 8px !important;
            aspect-ratio: 457/410;
        }
    }

    .parent:hover {
        .subMenu {
            visibility: visible !important;
            transform-origin: top center;
            animation: growDown 500ms ease-in-out forwards;
        }

    }

    .subMenu:hover {
        visibility: visible !important;
    }

    @keyframes growDown {
        0% {
            transform: scaleY(0)
        }

        80% {
            transform: scaleY(1.1)
        }

        100% {
            transform: scaleY(1)
        }
    }
}

.wrapperSwitch {
    overflow: hidden;
    z-index: 6;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    white-space: nowrap;
    
    @include MediaQuery($sm) {
        .itemActive {
            font-size: 1rem !important;
        }
    }

    .itemActive {
        font-weight: 500;
        font-size: 1.2rem;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-right: 1rem;
    }

    .itemInActive {
        margin: 0 15px 0 0;
        color: #8C8C8C;
        font-weight: 400;
        font-size: 1rem;
        display: flex;
        width: 145px;
        padding: 0 15px;
    }
}