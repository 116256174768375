$color-heading: #f5f5f5;
$tab_active: #f5f5f5;
$opacity: #BFBFBF;
$action: #D9D9D9;
$color-primary: #274FF5;

.ant-btn-primary {
  background-color: $primary;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: rgba(8, 151, 156, .8);
}

.bold {
  font-weight: 600 !important;
}

.cart__total-amount {
  width: 460px;
  //height: 7.5rem;
}

.cart__checkbox-active {
  color: #1677ff;
}


.box-info {
  background-color: #fff;

  input {
    background-color: #fff !important;
  }

  .label {
    //color: $opacity;
    color: #BFBFBF;
  }

  textarea {
    width: 100%;
    border-bottom: 1px solid $secondary;
    border-radius: 0;
    padding: 0;
    min-height: 4rem;
  }

  .box-item {
    [tpye=checkbox]:hover {
      cursor: pointer;
    }

    cursor: pointer;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  }
}

/** Tab bar **/
.carts_tab-bar {
  height: 100%;
  display: flex;
  cursor: pointer;
}

.carts_tab-bar-mobile {
  margin-top: 64px;
}

.carts_tab_bar-active {
  color: $color-primary;
  font-weight: 600;
  background-color: $white !important;
  font-size: 1rem;
}

.carts_tab-bar>div {
  background-color: $tab_active;
  width: 10rem;
  text-align: center;
}

.carts-profile {
  height: 100%;
  display: flex;
  gap: .5rem;
  font-weight: 400;
}

.carts_profile-img {
  width: 20px;
  height: 20px;
}

/** End Tab bar **/

/*** Cart table **/
.cart_table-column {}

.cart_table-column-item {
  color: #8C8C8C;
}

.cart_table_action {
  text-align: center;
  border-left: 1px solid $action;
  color: $textGrey;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*** End cart table **/

.carts {

  //min-height: calc(100vh - (var(--hegiht-header) + 2rem));
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;

  .heading {
    background: rgba(255, 255, 255, 0.3);
    //background-color: $color-heading;
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .cart-products {

    //width: 100%;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 1rem;

      .total_amount {
        font-weight: 600;
        color: $danger;
        font-size: 1rem;
      }

      .action {
        //width: 5rem;
        text-align: center;
        border-left: 1px solid $action;
        color: $textGrey;
        //padding: 0 1rem;
        //font-weight: bold;
      }

      .checkbox {
        cursor: pointer;
      }

      .checkbox-item {
        position: absolute;
        top: 8px;
        left: 8px;
      }

      .cart-underline {
        color: $opacity;
      }
    }
  }

  .delivery-information {
    .heading {
      height: 3.5rem;
      line-height: 3.5rem !important;
      //text-transform: uppercase;
      font-weight: 600;
      padding-left: 1rem;
      background-color: $white;
    }

    .content {
      background-color: rgba(255, 255, 255, .3);

      textarea {
        width: 100%;
        border: none;
        border-radius: .5rem;
        padding: 1rem;
      }


    }
  }

  .bg-white {
    background-color: $white !important;
  }

  .cart-box {
    height: 100%;
    background-color: rgba(255, 255, 255, .7) !important;

    .box-right-item {
      height: calc(100% - 5rem);
    }

    .heading {
      background-color: transparent !important;
      height: 3.5rem;
      line-height: 3.5rem !important;
      font-weight: 600;
    }

    .line-bottom {
      border-bottom: 1px solid $opacity;
    }

    .footer-amount {
      width: 100%;

      .total_amount {
        color: $danger;
      }

      .total_box {
        font-size: 1.7rem;
      }

      .term-of-payment {
        //color: $opacity;
        background-color: #FFFBE6;
        padding: 1rem;
        margin-bottom: 1rem;

        .title {
          font-size: 1rem;
        }
      }
    }

    .content-payment-info {
      height: calc(100% - 4.5rem);
    }
  }

  .total_payment {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .billing-information {
    height: 100%;
    background-color: $gray !important;

    .card-type-active {
      & .card-item-image {
        border: 1px solid $gray;
      }

      & .card-item-name {
        color: $gray;
      }
    }



    .heading {
      background-color: $gray !important;
      height: 3.5rem;
      line-height: 3.5rem;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}


@media only screen and (max-width: 768px) {
  .carts_tab-bar>div {
    width: 6rem;
  }

  .carts_profile-address {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    white-space: nowrap;
  }

  .carts_profile-text-action {
    display: none;
  }

  .cart_header-line-step {
    width: 12px !important;
  }

  .cart_header-text-action {
    display: none;
  }

  .cart_btn-footer-payment {
    min-width: 3rem !important;
    padding: 0 .5rem;
  }

  .cart__total-amount {
    width: 460px;
    height: 7.5rem;
    padding: 0 .5rem;
  }

  .carts_profile-address-lg {
    width: 240px !important;
  }
}

.carts__left-content {
  height: 72vh;
  overflow-y: auto;
}

.truncate-text-max-length {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: break-spaces;
}

.carts__left-content::-webkit-scrollbar {
  width: 0px !important;
  background-color: transparent;
}

@media only screen and (max-width: 500px) {
  .carts_tab-bar>div {
    width: 6rem;
  }

  //.text-lg {
  //  font-size: 10px !important;
  //}

  .carts_profile-address {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 32px;
    white-space: nowrap;
  }

  .carts_profile-text-action {
    display: none;
  }



  .cart-box {
    min-height: 20rem;
    height: 100% !important;
  }

  .carts__left-content {
    height: auto;
    overflow: hidden;
  }

  .product_detail_right--row {
    gap: 8px;
  }

  .product_detail_right--h {
    font-weight: 400;
    font-size: 14px;
    color: #434343;
  }

  .input_number {
    height: 38px;
    width: 88px;

    input {
      height: 38px;
    }

    .inner {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .total_payment {
    font-size: 16px !important;
  }

  .cart__header-component-content img {
    margin: initial !important;
  }
}

.carts-image {
  width: 96px !important;
  height: 96px !important;
  object-fit: cover;
}