.product {
    font-family: 'Open Sans', sans-serif;

    .product_other {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 500 !important;
            color: #595959 !important;
            font-size: 1.5rem !important;
        }
    }



}

.color_box {
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid #BFBFBF;
    border-radius: 2px;
}

.color_box--not_choose {
    cursor: pointer;
    opacity: 0.7;
}

.color_box--active {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 2px solid #08979C;
}

.color_box--out {
    position: absolute;
    border: 1px solid #BFBFBF;
    transform: rotate(-45deg);
    left: -4px;
    bottom: 6px;
    right: -6px;
    width: 20.89px;
    height: 1px;
}

.size_box {
    cursor: not-allowed;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid #BFBFBF;
    border-radius: 2px;
    display: flex;
    font-weight: 700;
    font-size: 11.4444px;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    position: relative;

}

.size_box--active {
    cursor: pointer;
    background: #434343;
    color: #ffff;
}

.size_box--not_choose {
    cursor: pointer;
    background: #FFFFFF;
    color: #434343;
}

.size_box--out {
    cursor: not-allowed;
    position: absolute;
    border: 1px solid #BFBFBF;
    transform: rotate(-45deg);
    left: -5px;
    bottom: 10px;
    right: -5px;
    width: 31.89px;
    height: 1px;
}

.input_number {
    z-index: 4;
    height: 44px;
    width: 120px;
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 4px;

    .inner {
        z-index: 20;
        flex: 1;
        margin: auto;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        align-items: center;
    }

    input {
        width: 30px;
        padding: 4px;
        height: 42px;
        border: none;
        background: transparent;
    }

    input:focus {
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

@media (max-width: 1280px) {
    .product_detail {
      width: 100% !important;
    }
  }

@media (max-width: 640px) {
    #right_product {
        padding-right: .75rem !important;
    }
}
  

.product_detail {
    display: flex;
    flex-direction: row;
    width: 1280px;
    margin: auto;

    #wrap_thumbnail{
        margin-right: 24px !important;
        gap: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .product_detail_left {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 4px;

        .product_detail_left--lower {
            display: flex;

            width: 100%;
            gap: 6px;
        }
    }

    .product_detail_right {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .product_detail_right_upper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px 24px 0 24px;

        .product_detail_right_curl {
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #6E87AA;
            font-size: 0.75rem;
        }

        .product_detail_right--title {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;
            color: #434343;
        }

        .product_detail_right--instruction {
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #434343;
            font-weight: 500;

        }

        .product_detail_right--prices {
            display: flex;
            flex-direction: row;
            gap: 24px;

            .product_detail_right--price {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .product_detail_right--price-current {
                    color: #434343;
                    font-weight: 400;
                    font-size: 1.2rem;
                    color: #E03616;
                }

                .product_detail_right--price-old {
                    color: #dfdfdf;
                    font-weight: 400;
                    text-decoration: line-through;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }


    }

    .product_detail_right_middle {
        // flex: 1;
        // overflow-y: auto;
        // overflow-x: hidden;
    }

    .product--rent_sell_day {
        z-index: 1;
        min-height: 82px;
        width: 348px;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        border-radius: 8px 8px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 4;
        padding: 12px 16px;
    }

    .product--rent_package {
        cursor: pointer;
        display: flex;
        padding: 12px 16px;
        flex-direction: row;
        z-index: 1;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        border-radius: 8px 8px 0px 0px;
        gap: 8px;
    }

    .product--rent_policy {
        cursor: pointer;
        z-index: 1;
        display: flex;
        padding: 12px 16px;
        flex-direction: row;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        border-radius: 8px 8px 0px 0px;
        gap: 8px;
    }
}

.product_detail_right_bottom {
    justify-content: space-between;
    z-index: 4;
    position: relative;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding: 16px 24px 16px 24px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);

    .product_detail_right_bottom--sum {
        color: #595959;
        font-size: 1rem;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .product_detail_right_bottom--boxes {
        display: flex;
        gap: 16px;

        .box {
            width: 7.25rem;
            height: 7.25rem;
            border-radius: 24px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                color: #434343;
                font-size: 0.9rem;
                font-weight: 500;
                word-wrap: break-word;
            }
        }
    }
}

.product_detail--popup {
    width: auto !important;
    min-height: auto !important;
    height: 100%;

    .tab_product {
        height: 100% !important;
        max-height: none !important;
        padding: 16px 0 !important;
        gap: 1px;
    }

    .product_detail_right_bottom {
        flex-direction: column;
        gap: 1rem;
    }

    .button_submit {
        width: 100%;
        padding: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #13C2C2;
        border-radius: 4px;
        color: #FFFFFF;
        font-weight: 500;
    }

    .product_detail_right_upper {
        gap: 6px !important;
    }
}

.product--layout--rent {
    z-index: 4;
    background: white;
    top: calc(50% - 41px - 122px);
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-left: 24px;
    width: 100%;
    align-items: end;
    left: 0;

}

.product_actions_layout:hover {
    visibility: visible !important;
}

.product_actions_layout:hover.cart {
    visibility: hidden !important;
}

.product_actions_layout {
    visibility: hidden;
    position: absolute;
    right: 10px;
    bottom: 0px;
    width: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .product_actions {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        p {
            color: #fff;
            opacity: 0.75;
        }

        .product_actions--circle {
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(4px);
            border-radius: 99999px;
            width: 52px;
            height: 52px;
            display: flex;

            .circle {
                color: rgba(255, 255, 255, 0.5);
                background: #FFFFFF;
                border-radius: 99999px;
                margin: auto;

                .icon {
                    background: rgba(255, 255, 255, 0.5);
                }


            }
        }
    }


}

.cart:hover+#product_actions_layout {
    visibility: visible !important;

}

.cart:hover {
    visibility: hidden !important;
}

.product_actions--circle:hover {
    background: #FFFFFF !important;
    width: 64px !important;
    height: 64px !important;


    .circle {
        z-index: 10;
        background: #13C2C2 !important;
    }

    .ico_action {
        path {
            fill: #13C2C2 !important;
        }
    }


}

.product_actions:hover {
    p {
        color: #fff !important;
        opacity: 1;
    }
}

.product_detail_bar {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
}

.product_detail_bar--inner {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.product--rent_package--mobile {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.product--rent_policy--mobile {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.product--rent_sell_day--mobile {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.product_detail_right--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .product_detail_right--row--boxes {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
    }

    .product_detail_right--h {
        font-size: 1.25rem;
        font-weight: 400;
        font-size: 20px;
        color: #434343;
    }
}

.popup_order_bottom {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.popup_order_near_bottom {
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
}

.popup_order_near_bottom_inner {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
}

.rent_pack_shadow {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
}

.rent_policy_shadow {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
}

.popup_sign_up_header {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.product_detail_right_bottom--content {
    width: 250px;

    .product_detail_right_bottom--detail {
        color: #595959;
        font-weight: 300;
        font-size: 0.9rem;
        margin-bottom: 16px;
    }

    .product_detail_right_bottom--rent {
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 24px;
        color: #E03616;
        margin-bottom: 8px;
    }

    .product_detail_right_bottom--sell {
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 24px;
        color: #E03616;
    }
}

.product-right-shadow {
    // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

#right_product {
    // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
    //     0px 2px 4px rgba(0, 0, 0, 0.08);
    .product_tabs{
        .product_tabs_heads{
            margin: 0px !important;
        }
    }
}

.description_detail {
    // background: #F5F5F5;
    // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    // border-radius: 16px;
}

.sticky {
    position: sticky !important;
    position: -webkit-sticky  !important;
    top: 48px;
}


@media (min-width: 640px) {
    .container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media (min-width: 1024px) {
    .container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.left_pro {
    padding-left: 1.5rem;
}


// @media (min-width: 640px) {
//     .left_pro {
//         padding-left: 20px;
//     }
// }

// @media (min-width: 768px) {
//     .left_pro {
//         padding-left: calc((100% - 768px)/2) !important;
//     }
// }

// @media (min-width: 1024px) {
//     .left_pro {
//         padding-left: calc((100% - 1024px)/2) !important;
//     }
// }

// @media (min-width: 1200px) {
//     .left_pro {
//         padding-left: calc((100% - 1280px)/2) !important;
//     }
// }

// @media (min-width: 1280px) {
//     .left_pro {
//         padding-left: calc((100% - 1280px)/2) !important;
//     }
// }

// @media (min-width: 1536px) {
//     .left_pro {
//         padding-left: calc((100% - 1536px)/2) !important;
//     }
// }



.btn-readmore{
    display: none;
    padding: 0.6rem 1rem;
    color: #006D75;
    border: solid 2px #006D75;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 1400px) {
    .wrap_content_mobile {
        height: 100px !important;
        overflow: hidden;
        .e-content {
            padding: 1rem !important;
        }
    }

    .wrap_content_mobile_full {
        height: auto !important;
        overflow: visible !important;
    }

    .btn-readmore {
        display: block;
    }
}

.aActive {
    color: #08979C;
    font-weight: 500;
    text-decoration: underline;
    font-size: 0.8rem;
    cursor: pointer;
}

.bg-noti-footer{
    position: fixed;
    width: 680px;
    height: 100px;
    background: #006D75;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    font-size: 1.1rem;
    padding: 1.5rem;
    bottom: 50%;
    color: white;
    border-radius: 10px;
    .icon-close{
        position: absolute;
        right: 0;
        top: 0;
        background: #FFF;
        padding: 0px 8px;
        color: #006D75;
        border-radius: 0 10px 0 0;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media (max-width: 1280px) {
        width: 100%;
    }
}