.btn {
  height: 2.5rem;
  background-color: $white;
  padding: 0 .75rem;
  border-radius: .25rem;
  min-width: 5rem;
}

.btn-primary {
  background-color: $primary;
  color: $white;
}

.btn-primary-outline {
  border: 1px solid $primary;
  color: $dark;
}

.btn-primary-outline:hover {
  background-color: $primary;
  opacity: .9;
  color: $white;
}

.btn-sm {
  height: 2rem !important;

}

.btn-secondary {
  border: 1px solid #BDBDBD;
}