.filter_layout {
    position: fixed;
    background: #fff;
    right: 0;
    top: 18%;
    width: 272px;
    height: 80vh;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 22;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 0 8px 0;
    .close {
        transition: all 200ms;
        font-size: 1.2rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
        z-index: 10;
        cursor: pointer;
    }

    .heading {
        color: #434343;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 500;
        font-style: normal;
    }

    .filter_layout--heading {
        background-color: #fff;
        z-index: 4;
    }

    .wrap-filter-btn{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 10px;
        gap: 0.25rem;
        height: 3rem;

        button{
            width: 50%;
            font-size: 0.875rem;
            cursor: pointer;
            padding: 8px 0;
            flex-grow: 1;
        }

        .reset-button{
            color: #000;
            background-color: #FFF;
            border: 1px solid #bfbfbf;
        }

        .filter_layout--button {
            // position: fixed;
            background-color: #006D75;
            color: #fff;
        }
    }

    

    .product_filter_group {
        padding: 1rem;
        margin-left: 0 !important;
        border-top: 1px solid #D9D9D9;
    }


}

.filter-buttons {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.filter_layout--category {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 2.5rem;
    padding-bottom: 16px;


}

.filter_layout--category--heading {
    color: #595959;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9rem;
}

.filter_layout--categories {
    padding-left: 0.4rem;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.active {
    color: #08979C !important;
}

.filter_layout--categories--child_zone {
    direction: rtl;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;

    a {
        //border-left: 1px dashed #D9D9D9;
        text-align: left;
        padding-left: 8px;
        direction: rtl;
    }
}

.filter_layout--line {
    border-left: 1px solid #D9D9D9;
}

.menu_parent--child {
    //padding-left: 16px;
    // border-left: 1px dashed #D9D9D9;
    direction: rtl;
    text-align: left;
}

.menu_parent {
    padding-left: 16px;
    border-left: 1px solid #D9D9D9;
}

.menu_parent--child--line {
    // border-left: 1px dashed #D9D9D9;
    // padding-left: 16px;
}

.menu_parent--child--line--active {
    color: #08979C;
    padding-left: 16px;
    border-left: 3px solid #08979C !important;
}