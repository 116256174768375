$primary: #08979C;
$secondary: #434343;
$white: #FFFFFF;
$danger: #dc3545;
$warning: #ffc107;
$success: #449d0c;
$dark: #030701;
$textGrey: #8C8C8C;
$gray: #f5f5f5;

.color-danger {
  color: $danger;
}

.background-color-gray {
  background-color: $gray;
}

.background-color-secondary{
  background-color: $secondary;
}

.background-color-primary {
  background-color: $primary;
}
