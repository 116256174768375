.wrap_popup {
    overflow: auto;
    visibility: visible !important;
    opacity: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 999;
    display: flex;
    justify-content: center;
    
    .map_popup {

        margin: 70px auto;
        background: #fff;
        border-radius: 5px;
        width: 50%;
        min-width: 1160px;
        position: relative;
        transition: all 5s ease-in-out;
        display: flex;
    }

    .order_pop {
        z-index: 1000;
        margin: 70px auto;
        background: #fff;
        border-radius: 5px;
        width: 70%;
        min-width: 1100px;

        position: relative;
        transition: all 5s ease-in-out;
        display: flex;

    }

    .sizeguide_pop {
        margin: 70px auto;
        background: #fff;
        border-radius: 5px;

        max-width: 800px;
        max-height: 100vh;
        // overflow: auto;
        // height: 600px;
        // width: 70%;
        position: relative;
        transition: all 5s ease-in-out;
        display: flex;
        left: 48%;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .map_pop{
        margin: 70px auto;
        background: #fff;
        border-radius: 5px;

        width: 90%;
        max-height: 90vh;
        top: 4%;
        // overflow: auto;
        // height: 600px;
        // width: 70%;
        position: relative;
        
    }

    .order_pop--header {
        padding: 24px;
    }

    .order_pop--header--main {
        font-weight: 700;
        color: #434343;
        font-size: 0.9rem;
        line-height: 1.25rem;
    }

    .order_pop--header--second {
        font-weight: 400;
        font-size: 0.8rem;
        color: #8C8C8C;
        line-height: 1rem;
    }

    .order_pop--form {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .search_input {
        position: absolute;
        top: 10px;
        left: 20px;
        transition: all 200ms;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.52);
        backdrop-filter: blur(5px);
        padding: 12px;
        min-width: 300px;
        color: #595959;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        z-index: 10;
        border-radius: 10px;
        height: 44px;

        .input {
            border: none !important;
            background-color: transparent;
            height: 44px;
        }

        .input:focus {
            outline: none !important;
        }
    }

    .map_component {
        width: 100%;
        height: 60vh;
        border-radius: 5px;
        overflow: hidden;
        display: flex;

        .marker_title {
            position: absolute;
            top: -50px;
            left: -100px;
            padding: 8px 24px;
            width: 250px;
            color: #13C2C2;
            font-weight: 500;
            font-size: 1.2rem;
            background: #FFFFFF;
            border: 1px solid rgba(255, 255, 255, 0.52);
            backdrop-filter: blur(5px);
            border-radius: 10px;
        }

        .map_locations {
            position: absolute;
            bottom: 0;
            z-index: 10;
            width: 100%;
            height: 168px;
            background: rgba(255, 255, 255, 0.6);
            border: 1px solid rgba(255, 255, 255, 0.52);
            border-radius: 0px 0px 16px 16px;
            display: flex;
            flex-direction: row;
            padding: 24px;
            gap: 24px;

            .location {
                cursor: pointer;
                background: #FFFFFF;
                border-radius: 8px;
                flex: 1;
                padding: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;

                h3 {
                    font-weight: 500;
                }

                p {
                    font-weight: 400;
                    color: #595959;
                }
            }

            .active {
                border: 1px solid #40A9FF !important;
            }
        }
    }

    .close {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 20px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #333;
        z-index: 10;
    }
}