.banner-blog {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    height: 25rem;
    position: relative;
    overflow: hidden;

    .banner-backgroud {
        filter: blur(2px);
        -webkit-filter: blur(2px);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .banner-content {
        position: absolute;
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        text-align: center;
        background: rgb(38 38 38 / 80%);

        @include MediaQuery($sm) {
            .last-title {
                width: auto !important;
            }
        }

        .fist-title {
            font-size: 4rem;
        }

        .last-title {
            font-size: 1.14rem;
            width: 80%;
        }
    }
}

.new-blog-title {
    display: flex;
    text-transform: uppercase;
    color: #595959;
    font-size: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 3.25rem;
    margin-bottom: 2.75rem;
    justify-content: center;
}

.new-blog {
    display: flex;
    flex-direction: column;
    position: relative;

    .wrap-posts {
        width: 55rem;
        height: 39rem;
        overflow: hidden;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        margin: 0px auto;
        position: relative;

        @include MediaQuery($sm) {
            width: 100%;
            height: auto;
            aspect-ratio: 343/239;

            .title-posts {
                width: 70% !important;
                height: auto !important;

                div {
                    gap: 4px !important;
                }

                .title {
                    margin-bottom: 4px !important;
                    font-size: 0.9rem !important;
                }

                .date-create {
                    font-size: 0.8rem;
                }
            }
        }

        .new-blog-image {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .title-posts {
            width: 34.875rem;
            height: 12.688rem;
            background-color: white;
            position: absolute;
            border-radius: 0.5rem;
            -webkit-border-radius: 0.5rem;
            -moz-border-radius: 0.5rem;
            -ms-border-radius: 0.5rem;
            -o-border-radius: 0.5rem;
            bottom: 1.5rem;
            left: 1.5rem;
            background-color: rgb(255 255 255 / 90%);
            backdrop-filter: blur(4px);
            padding: 1.5rem;
            font-family: 'Open Sans', sans-serif;

            .title {
                color: #434343;
                display: flex;
                font-size: 1.5rem;
                font-weight: bold;
                margin-bottom: 1.5rem;
            }

            .date-create {
                font-size: 1rem;
                color: #595959;
            }
        }
    }
}

.line {
    border: 0.5px solid #D9D9D9;
    display: flex;
}

// p {
//     white-space: normal !important;
//     font-weight: normal;
// }

.list-posts {
    // width: calc(100% - 6rem);
    font-family: 'Open Sans', sans-serif;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    // margin: auto;

    span {
        font-weight: 400;
        color: #595959;
    }
}

.blog_btn {
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    color: #595959;
    border: 1px solid #F0F0F0;
    background: #FAFAFA;
    border-radius: 4px;
    padding: 5px 16px;
}

.one-post {
    display: inline-flex;
    flex-direction: column;
}

.image-post {
    width: 100%;
    height: 30vh;
    position: relative;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    overflow: hidden;
}