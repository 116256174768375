.product_tabs {
    display: flex;
    flex-direction: column;

    .product_tabs_heads {
        display: flex;
        flex-direction: row;
        margin-left: 24px;
        margin-right: 24px;
        border-bottom: 1px solid #F0F0F0;
    }

    .product_tabs_head {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .active {
            border-bottom: 3px solid #08979C;
        }

        .active>.product_tabs_head--tab-content {
            color: #08979C !important;
            font-weight: 500 !important;
        }

        .product_tabs_head--tab {
            line-height: 2rem;
            cursor: pointer;

            .product_tabs_head--tab-content {
                padding: 16px 0;
                color: #8C8C8C;
                font-weight: 400;
                font-size: 1.2rem;
            }
        }


    }

    .product_tabs_content {
        width: 100%;
        display: flex;
    }
}

.product_tabs_caution {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8C8C8C;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
}

.tab_product {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px 5px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // max-height: 300px;
    overflow-y: auto;
    padding-bottom: 86px;

    .product_item {
        cursor: pointer;
        width: 100%;
        min-height: 105px;
        display: flex;
        gap: 8px;
        background: #FFFFFF;
        padding: 16px;
        border-radius: 8px;
        gap: 17px;

    }

    @include MediaQuery($sm) {
        .product_item {
            gap: 10px !important;
        }
    }

    .product_item:hover {
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.08);
    }

    .product_item--form {
        width: calc(100% - 100px) !important;
        @media only screen and (max-width: 540px) {
            width: 100% !important;
        }

        .product_item--form--header {
            font-weight: 550;
            font-size: 1rem;
            color: #434343;
            overflow: hidden !important;
            max-width: 100% !important;
        }

        a > .product_item--form--header {
            color: $primary;
            cursor: pointer;
        }

        .product_item--form--child {
            color: #595959;
            font-weight: 400;
            font-size: 1rem;
            font-family: 'Open Sans', sans-serif;
        }

        .product_item--form--sl {
            color: #8C8C8C;
            font-size: 0.95rem;
            font-weight: 300;
            white-space: nowrap;
        }
    }

    
}

.tab_description {
    background-color: #ffff;
    padding-top: 6px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    //padding-bottom: 86px;
    // height: 300px;
    overflow-y: auto;
    font-size: 1rem;

    p {
        width: 100% !important;
        white-space: break-spaces !important;
        color: #434343;
        font-weight: 500;
    }
}

.tab_shop {
    background-color: #ffff;
    padding: 16px 2px;
    display: flex;
    // padding-bottom: 86px;
    // height: 300px;
    flex-direction: column;
    overflow-y: auto;

    .tab_shop--address {
        font-size: 0.9rem;
        font-weight: 400;
    }

    .tab_shop--phone {
        font-weight: 500;
        color: #434343;
        text-decoration: underline;
    }

    .tab_shop--map {
        color: #08979C;
        font-weight: 500;
        width: 80px;
        cursor: pointer;
        @media only screen and (max-width: 540px) {
            width: 50px;
        }
    }
}

.shop_tab_name {
    bottom: 8px;
    left: 8px;
    position: absolute;
    font-size: 1.25rem;
    font-weight: 500;
    color: #262626;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(1px);
    border-radius: 4px;
    padding: 12px 8px;
}

.shop_item {
    padding: 8px 16px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    background: #FFFFFF;
}

.product_item--image {
    width: 100px;
}
