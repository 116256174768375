@font-face {
    font-family: 'Open Sans', sans-serif;
    src: url("../public/font/OpenSans-Regular.ttf");
    font-display: swap;
}

.products_head {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    height: 90px;
    background-image: url(../public/asset/image/products_head.webp);
    background-repeat: no-repeat;
    background-size: cover;

    @include MediaQuery($sm) {
        height: 50px;

        .wrapperProducts {
            width: 100% !important;

            .itemActive {
                font-size: 1rem !important;
            }

            .wrapperScroller {
                top: 5px !important;
                left: 0 !important;
            }
        }
    }

    .opacity_bg {
        position: absolute;
        width: 100%;
        height: 90px;
        background: #000000;
        opacity: 0.6;
        display: flex;
        justify-content: center;
        align-items: center;

        @include MediaQuery($sm) {
            height: 50px;
        }
    }

    .wrapperProducts {
        margin: auto;
        z-index: 5;
        opacity: 1 !important;
        white-space: nowrap;
        width: 90%;
        height: inherit;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;

        .wrapperScroller {
            transition: all 150ms ease-in;
            position: relative;

            span {
                cursor: pointer;
            }
        }

        .itemActive {
            margin: 0 15px;
            font-weight: 500;
            font-size: 2rem;
            color: #FFFFFF;
            text-transform: uppercase;
        }

        .itemInActive {
            margin: 0 15px;
            color: #BFBFBF;
            font-weight: 400;
            font-size: 1rem;
        }
    }
}
.prod_loading {
    height: 2000px;
}
.products {
    // padding-bottom: 28px;
    max-width: 1280px;
    padding: 1.5rem 0;
    @include MediaQuery($desktop) {
        padding: 1.5rem;
    }

    .products_body {
        width: 100%;
        display: grid;
        grid-template-rows: auto auto auto auto;
        gap: 1.5rem;
        grid-template-columns: repeat(4, 1fr);

        @include MediaQuery($md) {
            grid-template-columns: repeat(3, minmax(150px, 1fr));
            .header_content {
                margin-bottom: 4px !important;
            }

            .product_item_bottom {
                padding: 8px !important;
                //height: 34% !important;
            }

            // .cart {
            //     display: none !important;
            // }
        }

        @include MediaQuery($sm) {
            grid-template-columns: repeat(2, minmax(124px, 1fr));
        }
    }

    .products_pagination {
        width: 100%;
        padding: 16px 22px;
        background: #FFFFFF;
        /* Web/card-shadow */
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        margin: 1.5rem 0 0;

        .back_page {
            cursor: pointer;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 12px;
        }

        .next_page {
            cursor: pointer;
            // background: #FAFAFA;
            // border-radius: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 12px;
        }

        .page_item {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
        }

        .page_active {
            background-color: #13C2C2;
            color: #ffffff
        }
    }
}

.shadow_product_item {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
}

.product_item {
    cursor: pointer;
    position: relative !important;
    //height: 25rem;
    width: 100% !important;
    height: 100%;
    aspect-ratio: 293/400;
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
    -webkit-appearance: none;

    .wrap-img-product {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        img{
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .product_item_bottom {
        color: #FFFFFF;

        .header_content {
            max-width: 300px;
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .content {
            max-width: 250px;
            font-weight: 400;
            font-size: 14px;
        }

        .delete_content {
            font-size: 12px;
            color: #dfdfdf;
            text-decoration: line-through;
        }

        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: linear-gradient(179.49deg, rgba(0, 0, 0, 0) -8.35%, #000000 109.56%);
        padding: 24px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @include MediaQuery($md) {
        .product_item_bottom {
            background: #fff !important;
            position: relative !important;
        }

        .wrap-img-product {
            height: calc(100% - 104px) !important;
        }
    }


    // @include MediaQuery($sm) {
    //     .number_cart {
    //         position: absolute;
    //         top: 12px;
    //         right: 12px;
    //         padding: 0px 4px;
    //         align-items: flex-start;
    //         gap: 10px;
    //         border-radius: 4px;
    //         background: rgba(250, 250, 250, 0.40);
    //         backdrop-filter: blur(2px);
    //     }
    // }

    .product_sale {
        position: absolute;
        top: -14px;
        left: -10px;
    }
}

.product_other {
    .product_item {
        // margin: 0 12px;
    }
}

.cart {
    cursor: pointer;
    display: flex;
    width: 50px;
    height: 50px;
    background: linear-gradient(180deg, #8C8C8C 0%, rgba(140, 140, 140, 0) 100%);
    border-radius: 28px;
}

.product_detail_right--group {
    z-index: 4;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08)
}

.filter_button {
    width: 145px !important;
    height: 145px;
    display: flex !important;
    flex-direction: row !important;
    padding: 0px 0px !important;
    cursor: pointer;
    transform: rotate(-90deg);
    vertical-align: middle;
    text-align: center;

    .red-dot {
        background: #FF4D4F;
        width: 8px;
        height: 8px;
        border-radius: 999999px;
    }

    .content {
        border-left: 1px solid #DFDFDF;
        padding: 0 0 0 4px !important;
        height: 33px;
        line-height: 33px;
        flex-direction: row !important;
        gap: 5px !important;
        font-size: 0.9rem !important;

        img{
            height: 16px !important;
        }
    }
}

@include MediaQuery($sm) {
    .filter_button {
        display: none;
    }
}

.products_bottom {
    padding: 24px 18rem;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    // height: 22rem;
    background: #262626;
    /* Web/card-shadow */
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;

    @include MediaQuery($sm) {
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .products_bottom_image {
            border-radius: 8px;
            // visibility: hidden !important;
        }
    }

    .products_bottom_image {
        border-radius: 8px;
        display: flex;
        height: 21rem;

        .products_bottom_image--image {
            position: absolute !important;
            right: 24px;
            border-radius: 10px;
            aspect-ratio: 457/410;
            height: 410px;
        }

        .products_bottom_image--image--center {
            position: absolute !important;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
            aspect-ratio: 457/410;
        }
    }

    .products_bottom_contents {
        color: #ffffff;
        flex: 1;
        display: flex;
        flex-direction: column;

        .products_bottom_contents--header {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 1.5rem;
        }

        .read_more {
            background: #595959;
            border-radius: 4px;
            padding: 12px 24px;
            text-align: center;
        }

        @include MediaQuery($sm) {
            gap: 16px;

            .products_bottom_contents--button {
                display: flex;
                justify-content: center;
            }

            .products_bottom_contents--header {
                color: #FFFFFF;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }
}

.number_cart {
    position: absolute;
    top: 12px;
    right: 12px;
    background: #000000;
    border-radius: 2px;
    font-size: 11px;
    padding: 4px 12px;
    text-align: center;
    color: #ffffff
}

.wrap_content_filter {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    overflow-y: auto !important;
    gap: 1rem !important;
    flex-direction: column !important;
    display: flex !important;
    margin-top: 3.5rem !important;
    margin-bottom: 3rem !important;
}

.btn-filter {
    display: inline-flex !important;
    margin: 0 5px 5px 0;
}

.ant-slider .ant-slider-mark-text {
    left: 90% !important;
}

.product_tabs .product_tabs_head_list {
    @include MediaQuery($md) {
        padding: 0 1rem;
    }
}