$desktop: 1280px;
$lg: 1024px;
$md: 900px;
$sm: 568px;

// media queries

@mixin MediaQuery($type) {
    @media screen and (max-width:$type) {
        @content;
    }
}